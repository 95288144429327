

import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
  type Container,
  type ISourceOptions,
  Modes,
  MoveDirection,
  OutMode,
  OutModes,
} from "@tsparticles/engine";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { relative } from "path";
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.


export default function NewIdea() {

    const [init, setInit] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
      initParticlesEngine(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadAll(engine);
        //await loadFull(engine);
        await loadSlim(engine);
        //await loadBasic(engine);
      }).then(() => {
        setInit(true);
      });
    }, []);
  
    const particlesLoaded = async (container?: Container): Promise<void> => {
      console.log(container);
    };
  
    const options: ISourceOptions = useMemo(
      () => ({
        fullScreen:false,
        options:{
          preset: "bubbles"
        },
        background: {
          color: {
            value: "#4f46e5",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: "attract"
            },
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          move: {
            direction: MoveDirection.inside,
            enable: true,
            outModes: {
              default: OutMode.bounce,
            },
            random: true,
            speed: 1,
            straight: true,
          },
          number: {
            density: {
              enable: true,
            },
            value: 200,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }),
      [],
    );
  
    if (init) {
      return (

               
      <div className="">
           <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
          className="particles-new-idea"
          
        />
      <div className="mx-auto max-w-7xl sm:py-8 sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden px-6 py-14 sm:px-24 xl:py-2">
          <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">Have an idea for a new product?</h2>
          <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">We're the team that will get you to market, <b><u>quickly</u></b>!</p>
        <br />
        <div className="relative isolate overflow-hidden px-6 text-center">
          <a href="contact" >
            <button type="submit" className="flex-none rounded-md bg-white px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
              Let's Get Started!</button>
          </a>
          <br />
          <br />
        </div>
       
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
          className="particles-new-idea"
          
        />
         </div>
        {/* <p className="mx-auto mt-2 max-w-xl text-center text-md leading-8 text-gray-300 font-bold">15+ Years Experience</p> */}
      </div>
    </div>
        



      );
    }
  
    return <></>;

};
