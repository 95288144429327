import Nav from '../headers/nav';
import { Link } from 'react-router-dom';
import Pricing from '../home/pricing';

import complianceScore from '../img/compliance-score.png'
import defenderForCloud from '../img/defender-cloud.png'
import m365Dashboard from '../img/m365-security-center.png'
import crossCloud from '../img/compliance-control-list.png'

function Security() {

  return (
    <div>
      <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
        <Nav />
      </div>

      <div className="relative isolate overflow-hidden 
      bg-gray-900">

        <svg className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
          <defs>
            <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
        </svg>

        <div className="pb-0 mx-auto grid max-w-7xl grid-cols-1 gap-x-12 lg:gap-y-8">

          <div className="p-8 lg:col-end-1 lg:w-full lg:pb-8">

            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">Security Solutions</h1>

            <p className="mt-4 md:mt-4 text-lg leading-8 text-gray-300">
              Contact us today for a free security assessment. </p>
            {/* <p className="mt-4 md:mt-4 text-lg leading-8 text-gray-300">Security isn't optional, is required by law, and the severity of penalties are growing.</p> */}
          </div>
          <div className="flex flex-wrap gap-6 sm:gap-8 pl-10">
            <div className='h-22 pb-4 hidden md:block'>
            </div>
          </div>
        </div>
      </div>

{/* 
      <div className="bg-white py-12 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto lg:mx-0 text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Stay on top of customer support</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.</p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              <div className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z" />
                    </svg>
                  </div>
                  Unlimited inboxes
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">Non quo aperiam repellendus quas est est. Eos aut dolore aut ut sit nesciunt. Ex tempora quia. Sit nobis consequatur dolores incidunt.</p>
                  <p className="mt-6">
                    <a href="#" className="text-sm font-semibold leading-6 text-indigo-600">Learn more <span aria-hidden="true">→</span></a>
                  </p>
                </dd>
              </div>
              <div className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                  </div>
                  Manage team members
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">Vero eum voluptatem aliquid nostrum voluptatem. Vitae esse natus. Earum nihil deserunt eos quasi cupiditate. A inventore et molestiae natus.</p>
                  <p className="mt-6">
                    <a href="#" className="text-sm font-semibold leading-6 text-indigo-600">Learn more <span aria-hidden="true">→</span></a>
                  </p>
                </dd>
              </div>
              <div className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                  </div>
                  Spam report
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.</p>
                  <p className="mt-6">
                    <a href="#" className="text-sm font-semibold leading-6 text-indigo-600">Learn more <span aria-hidden="true">→</span></a>
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div> */}



      <div className="bg-white custom-bg">
        {/* <div className="lg:mx-auto max-w-2xl">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Zero-trust Architecture</h2>
            <p className=" text-2xl font-bold tracking-tight text-gray-900 xs:text-2xl">Zero-Trust Architecture</p>
            <p className="mt-6 text-lg leading-8 text-gray-600"></p>
          </div> */}



        <div className="py-4 sm:py-8">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-indigo-600">Cloud != Total Security</h2>
                <p className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Zero-trust Architecture</p>
                <p className="mt-4 text-base leading-7 text-gray-600">Zero-trust security is essential in the modern computing world.</p>

                <p className="mt-4 text-base leading-7 text-gray-600">If you're a company that's not leveraging zero-trust, you should be.</p>

                <p className="mt-4 text-base leading-7 text-gray-600">If you're a company in the cloud, but not paying attention to your remediation queue, you should be. </p>

                {/*                   
                   Azure provides a list of recommendations, but it's overwhelming, and finding time to remediate is difficult for busy IT teams.</p> */}

                {/* a set of paradigms that move defenses from static, network-based security controls, to individual users, assets, and resources.  */}

                <p className="mt-4 text-base leading-7 text-gray-600">Our <b>managed security services</b> fix that.</p>
                <br />
                {/* <img src={complianceScore} style={{ border: 'solid 1px #DDDDDD', marginTop: 5 }} /> */}
                <img src={crossCloud} style={{ border: 'solid 1px #DDDDDD', marginTop: 5 }} />
                {/* We help companies maintain a robust security posture in Azure with our co-managed security services.  */}

                {/* Azure provides a list of recommendations, but it's hard to find the time for remediation.   */}
              </div>
              <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2">




                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    Endpoint Security
                  </dt>
                  <dd className="">Endpoint security has never been more important. In fact, most threats originate from end users and their devices, so this is a critical part of your protection strategy.</dd>
                  <br />
                  <dt className="font-semibold text-gray-900">
                    <svg className="absolute left-0 top-44 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    Insurance Requirements
                  </dt>
                  <dd className="">Insurance companies have begun to require security score reporting for coverage.</dd>
                  <br />
                  <dt className="font-semibold text-gray-900">
                    <svg className="absolute left-0 top-88 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    Project-based Remediations
                  </dt>
                  <dd className="">Most clients prefer to segment and schedule remediations over time as part of their security program. </dd>
                </div>




                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    Governance Reporting
                  </dt>
                  <dd className="">
                    {/* Having a process is priority one, but reporting of process execution is equally important, and a common area for failure.  */}

                    We can help manage or co-manage your compliance program, with a relentless effort to increase your score. <img src={complianceScore} style={{ border: 'solid 1px #DDDDDD', marginTop: 5 }} />
                  </dd>
                </div>



                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    Microsoft Defender for Cloud
                  </dt>
                  <dd className="">
                    Defender is included in our managed services.
                    <img src={defenderForCloud} />
                  </dd>
                </div>



                <div className="relative pl-9">
                  <dt className="font-semibold text-gray-900">
                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    M365 Security Center
                  </dt>
                  <dd className="">Endpoint security is one of the most important pieces of zero trust, and rapid response capabilities.
                    <img src={m365Dashboard} />

                  </dd>
                </div>

              </dl>
            </div>
          </div>
        </div>


        <div className="relative bg-gray-900">
          <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
            <img className="h-full w-full object-cover" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply" alt="" />
            <svg viewBox="0 0 926 676" aria-hidden="true" className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]">
              <path fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)" fill-opacity=".4" d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z" />
              <defs>
                <linearGradient id="60c3c621-93e0-4a09-a0e6-4c228a0116d8" x1="926.392" x2="-109.635" y1=".176" y2="321.024" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#776FFF" />
                  <stop offset="1" stop-color="#FF4694" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
            <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
              <h2 className="text-base font-semibold leading-7 text-indigo-400">Professional Customer Service</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">We’re here to help</p>
              <p className="mt-6 text-base leading-7 text-gray-300">Our team of experts won't let you down. We're always happy, but never satisfied when it comes to security and will work relentlessly to strengthen your security posture.  </p>
              <div className="mt-8">
                <a href="/contact" className="inline-flex rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Contact Us Today</a>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Security;