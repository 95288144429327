import Nav from '../headers/nav';
import Pricing from '../home/pricing';

import cSharp from '../img/coding-bundle.jpg'
import nodeJs from '../img/node-js.svg'
import reactJs from '../img/reactjs-logo.jpeg'

function Software() {


  return (
    <div>
      <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
        <Nav />
      </div>



      <div className="bg-white">
        <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
          <div className="mx-auto max-w-7xl pb-4 pt-10 sm:pb-12 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-4">
            <div className="px-6 lg:px-0 lg:pt-4">
              <div className="mx-auto max-w-2xl">
                <div className="max-w-lg">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Software Development</h1>
                  <h2 className="mt-6 text-lg leading-8 text-gray-600">Your idea to BETA release in <u>only a few weeks</u>!</h2>
                  {/* <p className="mt-6 text-lg leading-8 text-gray-600">We bring concepts to BETA in only a couple of weeks. </p> */}
                  <div className="flex mt-4">
                  <span className="rounded-full bg-indigo-600/10 m-3 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">UX</span>
                  <span className="rounded-full bg-indigo-600/10 m-3 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">BackEnd</span>
                  <span className="rounded-full bg-indigo-600/10 m-3 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">Database</span>
                  </div>
                  <p className="mt-6 text-lg leading-8 text-gray-600"></p>
                  {/* <div className="mt-10 flex items-center gap-x-6">
              <a href="#" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Documentation</a>
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">View on GitHub <span aria-hidden="true">→</span></a>
            </div> */}
                </div>
              </div>
            </div>
            <div className="mt-10 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36" aria-hidden="true"></div>
              <div className="shadow-lg md:rounded-3xl">
                <div className="bg-indigo-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                  <div className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36" aria-hidden="true"></div>
                  <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                    <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                      <div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900">
                        <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                          <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                            <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">NotificationSetting.jsx</div>
                            <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>
                          </div>
                        </div>
                        <div className="px-6 pb-14 pt-6">
                        </div>
                      </div>
                    </div>
                    <div className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl" aria-hidden="true"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"></div>
        </div>
      </div>


      <div className="bg-white pb-10">
      <svg className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
    </svg>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/* <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Full Stack Solutions</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">We bring concepts to BETA in only a couple of weeks. </p>
          </div> */}
          <dl className="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
              <dt className="font-semibold text-gray-900">Custom Development </dt>
              <dd className="mt-1 text-gray-600">Custom front-end interfaces and core back-end technology. Development in .NET, NodeJS, C#, Python, ReactJS, TypeScript</dd>
            </div>
            <div>
              <dt className="font-semibold text-gray-900">Third-party Integrations</dt>
              <dd className="mt-1 text-gray-600">
                We have a team ready to build out third-party APIs and integrations with other providers. </dd>
            </div>
            <div>
              <dt className="font-semibold text-gray-900">CICD Pipelines & Dev Ops</dt>
              <dd className="mt-1 text-gray-600">Major gains for your stack by way of CICD, not just for code, but for your infrastructure too!</dd>
            </div>
            <div>
              <dt className="font-semibold text-gray-900">Identity Integrations (Entra, OKTA, OAUTH)</dt>
              <dd className="mt-1 text-gray-600">Poorly implemented Identity solutions lead to data breaches. We help companies integrate with identity providers instead of building their own or maintaining legacy directories.</dd>
            </div>
            <div>
              <dt className="font-semibold text-gray-900">Custom Workflows & Business Automations</dt>
              <dd className="mt-1 text-gray-600">Automate business processes and feed AI models with custom data flows. </dd>
            </div>
            <div>
              <dt className="font-semibold text-gray-900">Custom UX</dt>
              <dd className="mt-1 text-gray-600">We build complete UI/UX projects in ReactJS and other modern JS frameworks.</dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="relative bg-gray-900">
  <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
    <img className="h-full w-full object-cover" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply" alt="" />
    <svg viewBox="0 0 926 676" aria-hidden="true" className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]">
      <path fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)" fill-opacity=".4" d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z" />
      <defs>
        <linearGradient id="60c3c621-93e0-4a09-a0e6-4c228a0116d8" x1="926.392" x2="-109.635" y1=".176" y2="321.024" gradientUnits="userSpaceOnUse">
          <stop stop-color="#776FFF" />
          <stop offset="1" stop-color="#FF4694" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
    <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
      <h2 className="text-base font-semibold leading-7 text-indigo-400">Built on Customer Service</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">We’re here to help</p>
      <p className="mt-6 text-base leading-7 text-gray-300">Please don't hesitate to reach out, we love technology and talking about it. We guarantee you'll understand why so many companies have chosen us as their trusted IT partner. </p>
      <div className="mt-8">
        <a href="/contact" className="inline-flex rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Contact us today!</a>
      </div>
    </div>
  </div>
</div>
    </div>
  );
}

export default Software;