
function Pricing({dealId, title, description,  pointsTitle, points, priceTitle, price, footer}: {dealId:number, title:string, description:string, pointsTitle:string, points:any[], priceTitle:string, price:string, footer:string } ) {
    return (
     <div className="sm:my-10">
  <div className="mx-auto max-w-7xl">
     {/* <div className="mx-auto max-w-2xl sm:text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
      <p className="mt-2 text-lg leading-8 text-gray-600">Our essentials package is designed to get you up and running quickly. </p>
      <p className="mt-2 text-sm leading-8 text-gray-600">For questions, <a href="/contact" ><b>Contact Us</b></a> </p>
    </div>  */}
    <div className="bg-white mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
      <div className="p-8 sm:p-10 lg:flex-auto">
        <h3 className="text-2xl font-bold tracking-tight text-gray-900">{title}</h3>
        {/* Web Essentials */}

        <p className="mt-2 text-base leading-7 text-gray-600">{description}
          {/* This package gets you internet-ready quickly to kickstart SEO and start generating leads.  */}
          </p>
        <div className="mt-4 flex items-center gap-x-4">
          <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">{pointsTitle}
            {/* What’s included */}
            </h4>
          <div className="h-px flex-auto bg-gray-100"></div>
        </div>
        <ul role="list" className="mt-6 grid grid-cols-1 gap-4 text-md leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
          {points.map((p) => {
            return (
              <li className="flex gap-x-3">
              <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              {p}
              {/* Basic Website (Up to 5 pages) */}
            </li>
            )
          })}
{/*          
          <li className="flex gap-x-3">
            <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Annual Hosting
          </li>
          <li className="flex gap-x-3">
            <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            SEO Optimization
          </li>
          <li className="flex gap-x-3">
            <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Content/Tech Support
          </li> */}
        </ul>
        {/* <p className="mt-8 text-xs leading-8 text-gray-900">* Custom Websites up to 5 pages.&nbsp;&nbsp;Additional content services available. </p> */}
      </div>
      <div className="p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
        <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-10 ">
          <div className="mx-auto max-w-xs px-8">
            <p className="text-base font-semibold text-gray-600">{priceTitle}
              {/* Monthly/Annual */}
              </p>
            <p className="mt-6 flex items-baseline justify-center gap-x-2">
              <span className="text-5xl font-bold tracking-tight text-gray-900">{price}
                {/* $55/$549 */}
                </span>
              {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
            </p>
            {/* <a href={"/contact/" + dealId} className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"> */}
            <a href={"/contact"} className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Select Package</a>
            <p className="mt-6 text-xs leading-5 text-gray-600">{footer}
              {/* Save money with an Annual committment.  */}
              </p>
            {/* <p className="mt-2 text-sm leading-8 text-gray-600">For questions, <a href="/contact" ><b>Contact Us</b></a> </p> */}
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>

        // <div className="bg-white py-10 sm:py-10">
        //     <div className="mx-auto max-w-7xl px-6 lg:px-8">
        //         <div className="mx-auto max-w-4xl text-center">
        //         <h2 className="text-base font-semibold leading-7 text-indigo-600">Service Plans</h2>
        //         <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Plans for businesses of&nbsp;all&nbsp;sizes</p>
        //         </div>
        //         <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">The next three customers will recieve a %20 discount. </p>
        //         <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        //         <div className="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:mt-8 lg:rounded-r-none">
        //             <div>
        //             <div className="flex items-center justify-between gap-x-4">
        //                 <h3 id="tier-freelancer" className="text-lg font-semibold leading-8 text-gray-900">Web Essentials</h3>
        //             </div>
        //             <p className="mt-4 text-sm leading-6 text-gray-600">Everything necessary to be Web Ready.</p>
        //             <p className="mt-6 flex items-baseline gap-x-1">
        //                 <span className="text-4xl font-bold tracking-tight text-gray-900">$45</span>
        //                 <span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
        //             </p>
        //             <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Website Build (Up to 5 Pages)
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Modern Design/Mobile-responsive 
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Web Hosting (Annual)
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Visitor Analytics (Basic)
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Content Services (2 hours/month)
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 12-hr Support Tier 
        //                 </li>
        //             </ul>
        //             </div>
        //             <a href="#" aria-describedby="tier-freelancer" className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300">Buy plan</a>
        //         </div>
        //         <div className="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:z-10 lg:rounded-b-none">
        //             <div>
        //             <div className="flex items-center justify-between gap-x-4">
        //                 <h3 id="tier-startup" className="text-lg font-semibold leading-8 text-indigo-600">Standard</h3>
        //                 <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">Most popular</p>
        //             </div>
        //             <p className="mt-4 text-sm leading-6 text-gray-600">For growing businesses.</p>
        //             <p className="mt-6 flex items-baseline gap-x-1">
        //                 <span className="text-4xl font-bold tracking-tight text-gray-900">$245</span>
        //                 <span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
        //             </p>
        //             <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Multi-page Sites
        //                 </li>
        //                 {/* <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Advanced analytics
        //                 </li> */}
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Content Management (4 hours/month)
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Dedicated Support (Direct line)
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Platform Management Services 
        //                 </li>
        //             </ul>
        //             </div>
        //             <a href="#" aria-describedby="tier-startup" className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-indigo-600 text-white shadow-sm hover:bg-indigo-500">Buy plan</a>
        //         </div>
        //         <div className="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:mt-8 lg:rounded-l-none">
        //             <div>
        //             <div className="flex items-center justify-between gap-x-4">
        //                 <h3 id="tier-enterprise" className="text-lg font-semibold leading-8 text-gray-900">Enterprise</h3>
        //             </div>
        //             <p className="mt-4 text-sm leading-6 text-gray-600">Dedicated support and infrastructure for your company.</p>
        //             <p className="mt-6 flex items-baseline gap-x-1">
        //                 <span className="text-4xl font-bold tracking-tight text-gray-900">$945</span>
        //                 <span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
        //             </p>
        //             <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Unlimited Websites & data
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Dedicated Engineering Support
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Advanced analytics
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 1-hour, dedicated support response time
        //                 </li>
        //                 <li className="flex gap-x-3">
        //                 <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        //                     <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
        //                 </svg>
        //                 Marketing automations
        //                 </li>
        //             </ul>
        //             </div>
        //             <a href="#" aria-describedby="tier-enterprise" className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300">Buy plan</a>
        //         </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Pricing;