import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import { PortableText } from '@portabletext/react'
import PostTile from '../blog/postTile'
import { createClient } from "@sanity/client"
import { PostObj } from '../models';
import { Link, useParams } from 'react-router-dom';
import Nav from '../headers/nav';


export const client = createClient({
    projectId: "y12zu0ze",
    dataset: "production",
    apiVersion: "2024-03-11",
    // Set to `true` for production environments
    useCdn: false,
})

const markdown = '# Hi, *Pluto*!'


function Post() {

    const { id } = useParams();

    const [post, setPost] = useState<PostObj[]>([]);
    const [otherPosts, setOtherPosts] = useState<PostObj[]>([]);

    function getMarkdownSections(markdownString: string) {
        return markdownString.split("/n/n");
    }

    useEffect(() => {

        // uses GROQ to query content: https://www.sanity.io/docs/groq
        async function getPosts() {
            const posts = await client.fetch('*[_type == "post" && isActive == true && slug.current != "' + id + '"]{ title, "slug": slug.current, description, publishDate, "featuredImage": featuredImage.asset->url, author->{name, title, "mugshot": mugshot.asset->url } }')
            return posts
        }

        async function getPost() {
            const posts = await client.fetch('*[_type == "post" && slug.current == "' + id + '"]{ title, "slug": slug.current, description, content, contentNew, publishDate, "featuredImage": featuredImage.asset->url, author->{name, title, "mugshot": mugshot.asset->url } }')
            return posts
        }

        getPost().then(r => {
            console.log(r);
            setPost(r)
        });

        getPosts().then(r => {
            setOtherPosts(r)
        });


    }, [id]);


    return (
        <div>
            <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
                <Nav />
            </div>

            <div className="bg-gray-900 pb-4 sm:py-8">
                <div className="bg-white mx-auto max-w-7xl px-6 lg:px-8 rounded py-10">
                    <div className=''>
                        {post && post.length > 0 && (
                            <div>
                                {/* <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">{post[0].title}</h1> */}

                                <div className="flex justify-start">

                                    <div className="w-48">
                                        <div className="relative">
                                            <img src={post[0].featuredImage} alt="" className="bg-gray-100 object-cover shadow" />
                                        </div>
                                    </div>
                                        <div className="ml-10">
                                         <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">{post[0].title}</h1>
                                            <div className="relative mt-6 flex items-center gap-x-4">
                                                <img src={post[0].author.mugshot} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
                                                <div className="text-sm leading-6">
                                                    <p className="font-semibold text-gray-900">
                                                        <a href="#">
                                                            <span className="absolute inset-0"></span>
                                                            {post[0].author.name}
                                                        </a>
                                                    </p>
                                                    <p className="text-gray-600">{post[0].author.title}</p>
                                                </div>
                                                
                                            </div>
                                            <div className="mt-2 text-xs">
                                            <time dateTime="2020-03-16" className="text-gray-500">{post[0].publishDate}</time>
                                        </div>
                                            {/* <p className="mt-4 line-clamp-3 text-sm leading-6 text-gray-600">{post[0].description}</p> */}

                                        </div>
                                </div>


                                <ReactMarkdown components={{
                                    // Map `h1` (`# heading`) to use `h2`s.
                                    h1(props) {
                                        const {node, ...rest} = props
                                        return <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl" {...rest} />
                                    },
                                    h2(props) {
                                        const {node, ...rest} = props
                                        return <h2 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl" {...rest} />
                                    },
                                    // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                                    em(props) {
                                        const {node, ...rest} = props
                                        return <i style={{color: 'red'}} {...rest} />
                                    },
                                    p(props) {
                                        const {node, ...rest} = props
                                        return  <p className="mt-8 text-md leading-6 text-gray-900" {...rest}></p>
                                    },
                                    b(props) {
                                        const {node, ...rest} = props
                                        return  <b {...rest} />
                                    }
                                }}>{post[0].contentNew}</ReactMarkdown>     
                                                            
                               
{/* 
                                <PortableText value={post[0].content} /> */}

                            </div>
                        )}
                    </div>
                </div>
            </div>
            <hr />
            <div className="bg-white custom-bg pb-10 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Other Posts</h2>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {otherPosts.map((op, idx) => {
                        return (
                            <PostTile key={idx} {...op} />
                        )
                    })}

                    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Post;