import React, { useState, useEffect } from 'react';

import { createClient } from "@sanity/client"
import { PostObj } from '../models';
import PostTile from '../blog/postTile';


export const client = createClient({
   projectId: "y12zu0ze", 
   dataset: "production", 
   apiVersion: "2024-03-11",
   // Set to `true` for production environments
   useCdn: false, 
})


function Posts() {

    const [posts, setPosts] = useState([]);

    useEffect(() => {

        // uses GROQ to query content: https://www.sanity.io/docs/groq
        async function getPosts() {

          //"author": *[_type == "author" && references(^._id)].author

            const posts = await client.fetch('*[_type == "post" && isActive == true]{ "id": _id, "slug": slug.current, title, description, publishDate, "featuredImage": featuredImage.asset->url, author->{name, title, "mugshot": mugshot.asset->url } }')
            return posts
        }

        getPosts().then(r => {
            console.log(r);
            setPosts(r)
        });



      }, []);


    return (

        <div className="mx-auto py-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
           
           {posts.map((props : PostObj, idx) => (
                <PostTile key={idx} {...props} />
            ))}
            
        </div>


    );
}

export default Posts;