import React, { useState } from 'react';
import logo from './logo.svg';
import grid from './grid.svg';

import Nav from '../headers/nav';
import Hero from '../home/hero';
import Solutions from '../home/solutions';
import Posts from '../blog/posts';

import { BrowserRouter, Route} from 'react-router-dom';

import { createClient } from "@sanity/client"
import Pricing from '../home/pricing';
import NewIdea from '../home/newIdea';

export const client = createClient({
  projectId: "y12zu0ze", 
  dataset: "production", 
  apiVersion: "2024-03-11",
  // Set to `true` for production environments
  useCdn: false, 
})


function Home() {

  return (
    <div className="App">
       <header className="absolute inset-x-0 top-0 z-50">
      <Nav />      
      </header>
      <Hero />
      <Solutions />
    
<br />

  {/* Custom Data Connectors (Monday to Database)

  Cloud Experts
 */}

{/* 
<div className="bg-gray-900 py-16 sm:py-24 lg:py-32">
  <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
    <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
      <h2 className="inline sm:block lg:inline xl:block">Want product news and updates?</h2>
      <p className="inline sm:block lg:inline xl:block">Sign up for our newsletter.</p>
    </div>
    <form className="w-full max-w-md lg:col-span-5 lg:pt-2">
      <div className="flex gap-x-4">
        <label htmlFor="email-address" className="sr-only">Email address</label>
        <input id="email-address" name="email" type="email" autoComplete="email" required className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
        <button type="submit" className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe</button>
      </div>
      <p className="mt-4 text-sm leading-6 text-gray-300">We care about your data. Read our <a href="#" className="font-semibold text-white">privacy&nbsp;policy</a>.</p>
    </form>
  </div>
</div> */}



<div className="bg-gray-900 py-24 sm:py-12">
                <div className="mx-auto max-w-6xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-base font-semibold leading-7 text-indigo-400">Cloud Infrastructure</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Solutions for Azure Cloud</p>
                        <p className="mt-4 text-lg leading-8 text-gray-300">We can help you build, maintain, and <u>contain</u  > your cloud costs. </p>
                    </div>
                    <dl className="mx-auto mt-10 mb-5 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                                </svg>
                                IAAS, PAAS, Serverless, Kubernetes, Docker
                            </dt>
                            <br />
                            <dd className="inline">We've helped design and build cloud infrastructure in sectors such as retail, finance, and healthcare. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                </svg>
                                Azure Tenant Config
                            </dt>
                            <br />
                            <dd className="inline">We'll help get you started with the basics of the cloud to secure your organization and its identities. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                </svg>
                                Private Cloud Networking
                                <br />
                            </dt>
                            <dd className="inline">The cloud is inherently public by default, but advanced networking solutions are available to create private cloud networks. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                </svg>
                                Cost Consulting
                                <br />
                            </dt>
                            <dd className="inline">We identify areas or savings and help ensure you're not overpaying for resources you don't need.   </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z" clip-rule="evenodd" />
                                </svg>
                                High Availability & Disaster Recovery 
                                <br />
                            </dt>
                            <dd className="inline">Consulting for High-Availability and Disaster-Recovery is available for customers with strict business continuty requirements. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                                </svg>
                                Cloud Security
                                <br />
                            </dt>
                            <dd className="inline">Just because you're in the cloud doesn't make you secure. The majority of cloud vulnerabilities originate from overlooked configurations. </dd>
                        </div>
                    </dl>
                </div>
            </div>
            
<NewIdea />

{/* 
      <div className="bg-indigo-800 sm:py-8">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden px-6 py-14 sm:px-24 xl:py-2">
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">Have an idea for a new product?</h2>
            <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">We'll get you to market, <b><u>quickly</u></b></p>
          <br />
          <div className="relative isolate overflow-hidden px-6 text-center">
            <a href="contact" >
              <button type="submit" className="flex-none rounded-md bg-white px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                Let's Get Started!</button>
            </a>
          </div>
          </div>
          
        </div>
      </div> */}
{/*     
      <div className="bg-white py-4 sm:py-8">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl sm:text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">The Essentials</h2>
      <p className="mt-2 text-lg leading-8 text-gray-600">Our essentials package is designed to get you up and running quickly. </p>
      <p className="mt-2 text-sm leading-8 text-gray-600">For questions, <a href="/contact" ><b>Contact Us</b></a> </p>
    </div>
    </div>
    </div> */}
    {/* <div className="bg-white py-4 sm:py-8">
      <Pricing />
      </div> */}
      
      <section className="bg-gray-900 py-24 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
              
              {/* <img className="h-12 self-start" src="https://tailwindui.com/img/logos/tuple-logo-white.svg" alt="" /> */}
              
              <div className="font-bold text-3xl text-white">QuSmart.AI</div>
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                
                <blockquote className="text-lg leading-8 text-white">
                  <p>I've never seen an IT company innovate as quickly as Mike and his team do. You can trust your product is in good hands and a job well done.  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  {/* <img className="h-14 w-14 rounded-full bg-gray-800" src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                  <div className="text-base">
                    <div className="font-semibold text-white">Tracy Levine</div>
                    <div className="mt-1 text-gray-400">Digital Transformation Officer of QuSmart.AI</div>
                  </div>
                </figcaption>
              </figure>
            </div>
            <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
              {/* <img className="h-12 self-start" src="https://tailwindui.com/img/logos/reform-logo-white.svg" alt="" /> */}
              <div className="font-bold text-3xl text-white">USIntegration</div>
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                
                <blockquote className="text-lg leading-8 text-white">
                  <p>I've been working with Mike for over 10 years. He is a highly skilled and trusworthy IT professional who can do it all. From new software modules to cloud infrastructure management, my IT needs are covered so I can focus on my business. </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  {/* <img className="h-14 w-14 rounded-full bg-gray-800" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                  <div className="text-base">
                    <div className="font-semibold text-white">Rick Evans</div>
                    <div className="mt-1 text-gray-400">Owner of USIntegration</div>
                  </div>
                </figcaption>
                
              </figure>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-white custom-bg py-8 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Recent Blog Activity</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">Current musings, technology woes, and everything in between. </p>
          </div>
          
          <Posts />

        </div>
      </div>

    </div>
  );
}

export default Home;