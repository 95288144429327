import Nav from '../headers/nav';
import Pricing from '../home/pricing';

import cSharp from '../img/coding-bundle.jpg'
import nodeJs from '../img/node-js.svg'
import reactJs from '../img/reactjs-logo.jpeg'

function Infrastructure() {

  return (
    <div>
      <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
        <Nav />
      </div>



      <main>
    <div className="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pb-14 lg:pt-8">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-6 sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
            <div className="lg:py-16">
              {/* <div className="hidden sm:mb-5 sm:flex sm:justify-center lg:justify-start">
                <a href="/solutions/cloud/defender" className="flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                  <span className="rounded-full bg-indigo-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">ISO, NIST, CISA </span>
                  <span className="ml-4 text-sm">We'll make sure you're compliant. </span>
                  <svg className="ml-2 h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                  </svg>
                </a>
              </div> */}
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">Azure Cloud</span>
                <span className="block text-indigo-400">Experts</span>
              </h1>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                We help companies navigate the cloud and innovate. 
                {/* From basic builds to advanced HADR solutions, we've got you covered.  */}
                 {/* architectures that meet modern data securyt requirements so you can rest knowing you safely and securely  */}
              </p>
              <div className="">
                <form action="#" className="sm:mx-auto sm:max-w-xl lg:mx-0">
                  <div className="sm:flex">
                    {/* <ul>
                      <li><p className="mt-3 text-sm text-gray-300 sm:mt-4">Private Cloud Networking</p></li>
                      <li><p className="mt-3 text-sm text-gray-300 sm:mt-4">Kubernetes and Containerization</p></li>
                      <li><p className="mt-3 text-sm text-gray-300 sm:mt-4">Infrastructre as Code - BICEP & Terraform</p></li>
                      <li><p className="mt-3 text-sm text-gray-300 sm:mt-4">Azure Marketplace Solutions</p></li>
                      <li><p className="mt-3 text-sm text-gray-300 sm:mt-4">Managed Cloud Security Service</p></li>
                    </ul> */}

                    <p className="mt-3 text-sm text-gray-300 sm:mt-4">Private Cloud Networking&nbsp;&nbsp;|&nbsp;&nbsp;Kubernetes and Containers&nbsp;&nbsp; |&nbsp;&nbsp; 
                      Infrastructre as Code &nbsp;&nbsp;|&nbsp;&nbsp; BICEP & Terraform&nbsp;&nbsp; |&nbsp;&nbsp; Azure Marketplace Solutions &nbsp;&nbsp;|&nbsp;&nbsp; Managed Cloud Security Service</p>
                    {/* <div className="min-w-0 flex-1">
                      <label for="email" className="sr-only">Email address</label>
                      <input id="email" type="email" placeholder="Enter your email" className="block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900">
                    </div> */}
                    {/* <div className="mt-3 sm:ml-3 sm:mt-0">
                      <button type="submit" className="block w-full rounded-md bg-indigo-500 px-4 py-3 font-medium text-white shadow hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900">Start free trial</button>
                    </div> */}
                  </div>
                  {/* <p className="mt-3 text-sm text-gray-300 sm:mt-4"><a href="#" className="font-medium text-white">terms of service</a>.</p> */}
                </form>
              </div>
            </div>
          </div>
          <div className="-mb-16 mt-12 sm:-mb-48 lg:relative lg:m-0">
            <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0">
              {/* <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ --> */}
              <img className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>

  <div className="relative bg-white py-24 sm:py-12 lg:py-10">
  <svg className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
    </svg>

  <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
    <h2 className="text-lg font-semibold text-indigo-600">A Wide Range Of Expertise</h2>
    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Infrastructure, Performance, Security, & <u>Cost</u>  </p>
    <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
      It's easy to get going in the cloud, but maintaining multiple environments, cost, and security posture can be challenging. Let us help
      
      {/* deeper discipline.   
      
      with great power comes great responsibility for companies to ensure they're protecting data and IP properly.   */}
    </p>
    <div className="mt-20">
      <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                  <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Automated Deployments</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">This is where companies see the biggest gains not only to <i>speed</i>, but to <i>quality</i> and <i>security</i> of their process.  </p>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                  <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Identity Security & Management </h3>
              <p className="mt-5 text-base leading-7 text-gray-600">Your identity solution matters. If your company isn't using a modern identity solution with multi-factor capabilities your organization is not secure.  </p>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                  <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">HADR & SLAs</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">High Availability and Disaster Recovery get expensive quickly, but necessary. We can help navigate these decisions. </p>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                  <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Advanced Security</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">The number of cloud security alerts has grown to nearly a full-time job. We consult and remediate for our clients. </p>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                  <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Integrations & Blueprints</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">We can consult and/or lead third-party integrations or Marketplace product installations in your Tenant.</p>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
            <div className="-mt-6">
              <div>
                <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                  <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 17.25v-.228a4.5 4.5 0 00-.12-1.03l-2.268-9.64a3.375 3.375 0 00-3.285-2.602H7.923a3.375 3.375 0 00-3.285 2.602l-2.268 9.64a4.5 4.5 0 00-.12 1.03v.228m19.5 0a3 3 0 01-3 3H5.25a3 3 0 01-3-3m19.5 0a3 3 0 00-3-3H5.25a3 3 0 00-3 3m16.5 0h.008v.008h-.008v-.008zm-3 0h.008v.008h-.008v-.008z" />
                  </svg>
                </span>
              </div>
              <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">Data Security</h3>
              <p className="mt-5 text-base leading-7 text-gray-600">LLMs, Datbases, VM Drives & Backups, Blob Storage & Queues. This is where security and redundancy matter the most.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div className="bg-white">
  <div className="mx-auto max-w-7xl px-6 py-20 sm:py-20 lg:items-center lg:justify-between lg:px-8">
    <h2 className="text-3xl tracking-tight text-center text-gray-900 sm:text-4xl"> <a className="text-indigo-500" href="tel:19189229194"><b>Call</b></a> or <a className="text-indigo-500" href="/contact"><b>contact us </b> </a>today!</h2>
    <br />
    {/* <p className="text-center">Please </p> */}
    {/* <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
      <a href="#" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Contact Form</a>
      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Contact Form <span aria-hidden="true">→</span></a>
    </div> */}
  </div>
</div>

<div className="bg-gray-900 py-24 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-base font-semibold leading-7 text-indigo-400">Infrastructure</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Cloud-based IAAS & PAAS Solutions</p>
                        <p className="mt-4 text-lg leading-8 text-gray-300">We build, maintain, and contain cloud costs. </p>
                    </div>
                    <dl className="mx-auto mt-10 mb-5 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                                </svg>
                                Azure Cloud Experts
                            </dt>
                            <br />
                            <dd className="inline">Enterprise-level cloud solutions for everyone. You don't need dedicated IT staff to be Cloud ready. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                </svg>
                                Landing Zones and Tenant Config
                            </dt>
                            <br />
                            <dd className="inline">Cloud directory migration and management to ensure you have a solid identity solution for your organization and/or your customers. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                </svg>
                                Private Cloud Networking
                                <br />
                            </dt>
                            <dd className="inline">The cloud is inherently public by default, but advanced networking solutions are available to create private cloud networks. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                </svg>
                                Lift & Shift Buildouts
                                <br />
                            </dt>
                            <dd className="inline">We help companies lift and shift existing on-prem infrastructure and workloads to the cloud securely using best-practices. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z" clip-rule="evenodd" />
                                </svg>
                                HADR Buildouts
                                <br />
                            </dt>
                            <dd className="inline">Consulting for High-Availability and Disaster-Recovery is available for customers with strict business continuty requirements. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                                </svg>
                                Cloud Security
                                <br />
                            </dt>
                            <dd className="inline">Just because you're in the cloud doesn't make you secure. The majority of cloud vulnerabilities originate from overlooked configurations. </dd>
                        </div>
                    </dl>
                </div>
            </div>

{/* 
<div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
      <div className="max-w-xl lg:max-w-lg">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Lets Get Scheduled</h2>
        <p className="mt-4 text-lg leading-8 text-gray-300">We would appreciate the opportunity to speak with you. Consults are free and don't require and commitment.</p>
        <div className="mt-6 flex max-w-md gap-x-4">
          <label className="sr-only">Email address</label>
          <input id="email-address" name="email" type="email" autoComplete="email" required className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
          <button type="submit" className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Subscribe</button>
        </div>
      </div>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
        <div className="flex flex-col items-start">
          <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
          </div>
          <dt className="mt-4 font-semibold text-white">Schedule Quickly</dt>
          <dd className="mt-2 leading-7 text-gray-400">We'll work around you to schedule quickly. NDA available upon request.</dd>
        </div>
        <div className="flex flex-col items-start">
          <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002" />
            </svg>
          </div>
          <dt className="mt-4 font-semibold text-white">No spam</dt>
          <dd className="mt-2 leading-7 text-gray-400">Your information is safe and will not be shared with anyone.</dd>
        </div>
      </dl>
    </div>
  </div>
  <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
    <div className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}}></div>
  </div>
</div> */}
      
    </div>
  );
}

export default Infrastructure;