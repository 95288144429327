import Nav from '../headers/nav';
import facilityKeeper from '../img/facility-keeper.png'
import qusmartfileShare from '../img/qusmart-file-share.png'

function Products() {

    return (
        <div>
            <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
                <Nav />
            </div>
            <div className="isolate bg-white px-6 py-4 sm:py-8 lg:px-8">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
                    <div className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                        style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }}></div>
                </div>
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Products</h2>
                    {/* <p className="mt-2 text-lg leading-8 text-gray-600">Please use the form below. We respond quickly! </p> */}
                </div>

            </div>

            <div className="overflow-hidden bg-white py-10 sm:py-8">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Data Obfuscation & Perfect Secrecy</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">QuSmart.AI</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">QuSmart.AI's Encryption as a Service (EaaS) product offers the ultimate shield for your data, fortifying your digital assets against the advanced strength of post-quantum cryptography (PQC) attacks.</p>
                                {/* <p className="mt-6 text-lg leading-8 text-gray-600">Threats include artificial intelligence, genetic algorithms, and quantum computing which is becoming more accessible every day.</p> */}
                                <p className="mt-6 text-lg leading-8 text-gray-600">Take your company to the next level of cybersecurity: Quantum Security.</p>
                                <br />
                                <a href="https://qusmart.ai/" target='_blank' >
                                <button type="submit" className="flex-none rounded-md bg-gray-900 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-gray-500 hover:text-gray-900 ">
                                Learn More</button>
                                </a>
                                {/* <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            <div className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                                </svg>
                                Push to deploy.
                            </dt>
                            <dd className="inline">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</dd>
                            </div>
                            <div className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                </svg>
                                SSL certificates.
                            </dt>
                            <dd className="inline">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.</dd>
                            </div>
                            <div className="relative pl-9">
                            <dt className="inline font-semibold text-gray-900">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                                <path fill-rule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clip-rule="evenodd" />
                                </svg>
                                Database backups.
                            </dt>
                            <dd className="inline">Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</dd>
                            </div>
                        </dl> */}
                            </div>
                        </div>
                        <img src={qusmartfileShare} alt="Encryption As A Service" />
                    </div>
                </div>
            </div>

            <div className="bg-white">
  <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
    <div className="overflow-hidden rounded-lg bg-indigo-700 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
      <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
        <div className="lg:self-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            <span className="block">Ready to dive in?</span>
            <span className="block">Start your free trial today.</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-indigo-200">Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.</p>
          <a href="#" className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 shadow hover:bg-indigo-50">Sign up for free</a>
        </div>
      </div>
      <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
        <img className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg" alt="App screenshot" />
      </div>
    </div>
  </div>
</div>

            <div className="overflow-hidden bg-gray-900 py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Facility Keeper</h2>
                                <p className="mt-6 text-lg leading-8 text-gray-300">Facility Keeper is a maintenance management app that allows users to build maintenance plans, automate work order creation, manage inventory, and complete work as a team. </p>
                                <p className="mt-6 text-lg leading-8 text-gray-300">It also features Push Notifications reminders and a Reporting module to keep a birds eye view on operations. </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                                    <div className="relative pl-9">
                                        <dt className="inline font-semibold text-white">
                                            <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                                            </svg>
                                            Automate Workflows
                                        </dt>
                                        <br />
                                        <dd className="inline">Reduce maintenance costs by scheduling preventative maintenance tasks.</dd>
                                    </div>
                                    <div className="relative pl-9">
                                        <dt className="inline-block font-semibold text-white">
                                            <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                            </svg>
                                            Proactive Alerts
                                        </dt>
                                        <br />
                                        <dd className="inline">Real-time management operations sent straight to your phone using our new cloud-based solution! Your team will receive notifications when assigned a task - and you're notified when tasks are completed.</dd>
                                    </div>
                                    <div className="relative pl-9">
                                        <dt className="inline-block font-semibold text-white">
                                            <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                            </svg>
                                            Efficiency
                                        </dt><br />
                                        <dd className="inline">Easily track work across your entire team from your phone, tablet, or computer.  </dd>
                                    </div>
                                </dl>
                            </div>
                            <br />
                            {/* <button type="submit" className="flex-none rounded-md bg-white px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                Learn More</button> */}
                                 <p className="mt-6 text-lg leading-8 font-bold text-gray-300">Contact us today to get started!</p>
                        </div>
                        <img src={facilityKeeper} alt="Maintenance Management" style={{ display: 'block' }} />
                        {/* <img src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png" alt="Product screenshot" className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" width="2432" height="1442" /> */}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Products;