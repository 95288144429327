import Nav from '../headers/nav';
import Pricing from '../home/pricing';

import cSharp from '../img/coding-bundle.jpg'
import nodeJs from '../img/node-js.svg'
import reactJs from '../img/reactjs-logo.jpeg'

function Solutions() {

    var webEssentialsPoints = ["Basic Website (Up to 5 pages)", "Annual Hosting", "SEO Optimization", "Content/Tech Support"]

    return (
        <div>
            <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
                <Nav />
            </div>
            <div className="isolate bg-white px-6 py-4 sm:py-8 lg:px-8">
                <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
                    <div className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                        style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }}></div>
                </div>
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Solutions & Services</h2>
                    {/* <p className="mt-2 text-lg leading-8 text-gray-600">Please use the form below. We respond quickly! </p> */}
                </div>

            </div>

            <div className="bg-white pb-10 sm:py-6">
                <div className="mx-auto pb-10 max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-indigo-600">Software Development</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Web, Mobile & <br /> Custom Apps</p>
                            {/* <div className="languages">
            <a className="language button button--outline button--primary" >Rust</a>
            <a className="language button button--outline button--primary" >NodeJS</a>
            <a className="language button button--outline button--primary" >Python</a>
            <a className="language button button--outline button--primary" >WASM</a>
        </div>   */}
                            <div className='flex mt-8' >
                                <img src={cSharp} className='inline pr-8' style={{ height: 70 }} alt="C#" />
                                <img src={nodeJs} className='inline pr-8' style={{ height: 70 }} alt="NodeJs" />
                                <img src={reactJs} className='inline pr-8' style={{ height: 70 }} alt="ReactJS" />
                            </div>
                            {/* <p className="mt-6 text-base leading-7 text-gray-600">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</p> */}
                        </div>
                        <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-4 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-10">
                            <div className="relative pl-9">
                                <dt className="font-semibold text-gray-900">
                                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                    </svg>
                                    Websites & Mobile Apps
                                </dt>
                                <dd className="mt-2">Web Development, Content Modernization, E-Commerce Solutions, Wordpress Migrations, Mobile Apps, Custom UX Development</dd>

                            </div>

                            <div className="relative pl-9">
                                <dt className="font-semibold text-gray-900">
                                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                    </svg>
                                    Custom Software Development
                                </dt>
                                <dd className="mt-2">Full-stack platform development, API & Data Services, Workflow Automation, AI Integrations, CI/CD & Deployments</dd>
                            </div>

                            <div className="relative pl-9">
                                <dt className="font-semibold text-gray-900">
                                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                    </svg>
                                    Customer Data Solutions
                                </dt>
                                <dd className="mt-2">Customer Facing Portals, Customer Relationship Databases, Email Automations</dd>
                            </div>

                            <div className="relative pl-9">
                                <dt className="font-semibold text-gray-900">
                                    <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                    </svg>
                                    Data & Workload Security
                                </dt>
                                <dd className="mt-2">Relational and non-relational databases, Automations and Flow Development, Workload Data Security</dd>
                            </div>
                            {/*       
        <div className="relative pl-9">
          <dt className="font-semibold text-gray-900">
            <svg className="absolute left-0 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            Custom UX Experiences
          </dt>
          <dd className="mt-2">Modernize your apps and reap the benefits of modern UX experiences. We can help build a foundation with code and training. </dd>
        </div>
         */}
                        </dl>
                    </div>
                </div>
            </div>

            
            <div className="bg-gray-900 py-24 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-base font-semibold leading-7 text-indigo-400">Infrastructure</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Cloud-based IAAS & PAAS Solutions</p>
                        <p className="mt-4 text-lg leading-8 text-gray-300">We build, maintain, and contain cloud costs. </p>
                    </div>
                    <dl className="mx-auto mt-10 mb-5 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                                </svg>
                                Azure Cloud Experts
                            </dt>
                            <br />
                            <dd className="inline">Enterprise-level cloud solutions for everyone. You don't need dedicated IT staff to be Cloud ready. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                                </svg>
                                Landing Zones and Tenant Config
                            </dt>
                            <br />
                            <dd className="inline">Cloud directory migration and management to ensure you have a solid identity solution for your organization and/or your customers. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                </svg>
                                Private Cloud Networking
                                <br />
                            </dt>
                            <dd className="inline">The cloud is inherently public by default, but advanced networking solutions are available to create private cloud networks. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                </svg>
                                Lift & Shift Buildouts
                                <br />
                            </dt>
                            <dd className="inline">We help companies lift and shift existing on-prem infrastructure and workloads to the cloud securely using best-practices. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z" clip-rule="evenodd" />
                                </svg>
                                HADR Buildouts
                                <br />
                            </dt>
                            <dd className="inline">Consulting for High-Availability and Disaster-Recovery is available for customers with strict business continuty requirements. </dd>
                        </div>
                        <div className="relative pl-9">
                            <dt className="inline font-semibold text-white">
                                <svg className="absolute left-1 top-1 h-5 w-5 text-indigo-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                                </svg>
                                Cloud Security
                                <br />
                            </dt>
                            <dd className="inline">Just because you're in the cloud doesn't make you secure. The majority of cloud vulnerabilities originate from overlooked configurations. </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="bg-white">
  <div className="mx-auto max-w-7xl px-6 py-20 sm:py-20 lg:items-center lg:justify-between lg:px-8">
    <h2 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">We're ready to chat when you are!<br /><br /> <a href="tel:19189229194"><u>Call</u></a> or use our <a href="/contact"><u>contact form</u>. </a></h2>
    <br />
    {/* <p className="text-center">Please </p> */}
    {/* <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
      <a href="#" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Contact Form</a>
      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Contact Form <span aria-hidden="true">→</span></a>
    </div> */}
  </div>
</div>
        </div>
    );
}

export default Solutions;