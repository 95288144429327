import Nav from '../headers/nav';
import Posts from '../blog/posts';

function Blog() {

    return (
        <div>
            <div className="relative inset-x-0 top-0 z-50 bg-gray-900">
            <Nav />
            </div>
            <div className="bg-white py-8 sm:py-8">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:max-w-4xl">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Blog</h2>
                        <p className="mt-2 text-lg leading-8 text-gray-600">Enjoy shared content about projects, research, and other triumphs. </p>
                        
                        <Posts />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blog;